import './SignUpFailContent.less'

import { useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import DatacIcon from '../../../assets/images/datac-icon.svg'
import NotFoundImage from '../../../assets/images/not-found.svg'
import { DatacSubtitle, DatacTitle } from '../../common'

export const SignUpFailContent: React.FC = () => {
  const intl = useIntl()
  const intlSignupFail = (scopedId: string) => intl.formatMessage({ id: `auth.signup_fail.${scopedId}` })

  return (
    <div className="signup-fail-content">
      <NotFoundImage className="signup-fail-content__image-not-found" />
      <DatacIcon className="signup-fail-content__datac-icon" />
      <DatacTitle type="h1" className="signup-fail-content__title">
        {intlSignupFail('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="signup-fail-content__subtitle">
        {intlSignupFail('subtitle')}
      </DatacSubtitle>
    </div>
  )
}
